import * as React from 'react';
// import '../sass/main.scss';

import FeatherIcon from 'feather-icons-react';
import LayoutDefault from '../layouts/default';
import TextHeader from '../components/textHeader';

// markup
const CaseStudyOnePage = () => {
  return (
    <>
      <LayoutDefault>
        <header
          className="page-header-ui page-header-ui-dark bg-img-cover py-15"
          style={{
            backgroundImage:
              'url("https://source.unsplash.com/RKwaj2g6OOU/1500x900")',
          }}
        >
          <div className="my-15 py-lg-10" />
          <div className="svg-border-rounded text-light">
            {/* Rounded SVG Border*/}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 144.54 17.34"
              preserveAspectRatio="none"
              fill="currentColor"
            >
              <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
            </svg>
          </div>
        </header>

        <section className="bg-light py-10">
          <div className="container px-5">
            <div className="row gx-5 justify-content-center">
              <div className="col-lg-8">
                <h1>Curology</h1>
                <p className="lead">
                  A case study focused on building brand standards and creating
                  effective advertising campaigns
                </p>
                <hr />
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Corrupti quis voluptas amet accusamus temporibus corporis quia
                  asperiores magni laudantium dolore iusto, adipisci rem id
                  ratione repellat! Fuga esse molestias accusamus!
                </p>
                <p className="mb-0">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Eveniet error pariatur voluptatibus consequuntur natus unde
                  porro, soluta quisquam facere, deserunt repellat iusto odit
                  labore doloremque velit qui mollitia quo numquam.
                </p>
              </div>
              <div className="col-lg-10">
                <img
                  className="img-fluid rounded-3 shadow my-5"
                  src="https://source.unsplash.com/DGH1u80sZik/1200x900"
                  alt="..."
                />
              </div>
              <div className="col-lg-8">
                <h4>Building Brand Awareness</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
                  a, iusto ratione minus labore illum sequi optio recusandae
                  perferendis, et beatae corrupti! Nemo aut inventore maxime
                  eligendi optio cupiditate et.
                </p>
                <p className="pb-5">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                  eius cupiditate similique officia aperiam adipisci
                  reprehenderit veniam voluptate eum fugit voluptatem,
                  aspernatur minus dolorum nemo impedit odit sit odio
                  consectetur!
                </p>
              </div>
              <div className="col-lg-10">
                <div className="row gx-5">
                  <div className="col-md-6">
                    <img
                      className="img-fluid rounded-3 mb-5"
                      src="https://source.unsplash.com/ZIihTgf5uGg/800x700"
                      alt="..."
                    />
                    <img
                      className="img-fluid rounded-3 mb-5"
                      src="https://source.unsplash.com/Eu_jPNRNub8/800x900"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-6">
                    <img
                      className="img-fluid rounded-3 mb-5"
                      src="https://source.unsplash.com/VItxz6u036U/800x900"
                      alt="..."
                    />
                    <img
                      className="img-fluid rounded-3 mb-5"
                      src="https://source.unsplash.com/E_dRKdBhxk4/800x700"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card bg-primary-soft text-primary text-center shadow-none mb-5">
                  <div className="card-body">
                    <p className="card-text lead">
                      "Working with this agency helped us boost sales and raise
                      brand awareness across all of our product lines."
                    </p>
                  </div>
                </div>
                <h4>Benchmarks &amp; Performance Metrics</h4>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa
                  provident hic quaerat. Accusamus quidem quos fugit a
                  aspernatur, magni obcaecati tenetur similique eaque, sequi id
                  alias veritatis eius, maiores animi.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                  neque ut reiciendis suscipit animi quaerat, est, repellat unde
                  distinctio hic sapiente asperiores perspiciatis id! Quasi
                  eveniet cupiditate quisquam. Itaque, esse.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
                  nobis odio modi optio sapiente neque saepe, facere culpa,
                  doloribus voluptatem nemo quam, cumque fugiat qui excepturi.
                  Nostrum, error. Impedit, quis!
                </p>
                <ul className="fa-ul">
                  <li className="mb-3">
                    <span className="fa-li" />
                    <i className="fas fa-check me-2 text-green" />
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  </li>
                  <li className="mb-3">
                    <span className="fa-li" />
                    <i className="fas fa-check me-2 text-green" />
                    Quidem dicta iste maiores eligendi numquam nam voluptas quia
                    nisi consequatur illo neque sunt error doloremque vel.
                  </li>
                  <li className="mb-3">
                    <span className="fa-li" />
                    <i className="fas fa-check me-2 text-green" />
                    Animi aliquam dolores suscipit saepe ratione corrupti
                    veniam.
                  </li>
                  <li>
                    <span className="fa-li" />
                    <i className="fas fa-check me-2 text-green" />
                    Molestiae minima aliquam consequatur eius ab dignissimos at
                    maxime odit culpa excepturi vero magnam vitae ipsa! Ducimus.
                  </li>
                </ul>
                <hr className="my-5" />
                <div className="text-center">
                  <a className="btn btn-transparent-dark fw-500" href="#!">
                    Go Back
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="svg-border-rounded text-dark">
            {/* Rounded SVG Border*/}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 144.54 17.34"
              preserveAspectRatio="none"
              fill="currentColor"
            >
              <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
            </svg>
          </div>
        </section>
      </LayoutDefault>
    </>
  );
};

export default CaseStudyOnePage;
